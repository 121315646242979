import axios from 'axios';
import { serverbaselink } from '../Env_Variables';
const axiosheaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};
export const fetchuseauthorization = (axiosdata) => {
    axios.defaults.withCredentials = true;
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/surfer/checkauth',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const toggleindiviualplaystoreconsole = (axiosdata) => {
    axios.defaults.withCredentials = true;
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/toggleindiviualplaystoreconsole',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const logincheck = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/surfer/logincheck',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const Signup_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/surfer/Signup',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const logout_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/logout',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const login_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/login',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const AddLead_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/addlead',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const FetchLeads_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/FetchLeads',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const SearchLeads_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/searchleads',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const addLeadActionMutation_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/addLeadActionMutation',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const updateleadlockstatusMutation_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/updateleadlockstatus',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const operationrequestcrud_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/operationrequestcrud',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const FetchCalls_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/FetchCalls',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const FetchIndiviualplaystoreconsoledeals_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/fetchIndiviualplaystoreconsoledeals',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const update_playstoreconsole_status_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/update_playstoreconsole_status',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const update_playstore_dunsnumber_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/update_playstore_dunsnumber',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const update_deal_docs_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/update_deal_docs',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const fetchAcceptanceMeetings_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/fetch_acceptance_meetings',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const onboardingacceptancemeeting_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/onboardingacceptancemeeting',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const fetchoperationsrequest_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/fetchoperationsrequest',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const campaignanalytics_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/campaignanalytics',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const Fetchmeetingsfollowups_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/Fetchmeetingsfollowups',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const FetchUsers_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/FetchUsers',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const submittabexwebsite_contactusform_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/surfer/submittabexwebsite_contactusform',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
//promocodes
export const fetchcouponsdata = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/fetchcoupons',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const couponsfunction = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/couponscrud',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
//securitylayers
export const dealchangerequest_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/FetchTabexSecurityLayers',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const Dealchangerequest_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/dealchangerequest',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const FetchInstitueSecurityLayers_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/FetchInstitueSecurityLayers',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const CRUDSecurityGroup_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/CRUDSecurityGroup',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
//users
export const fetchInstituteUsers = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/fetchinstusers',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const InstituteUserCrud = (axiosdata) => {
    // userinfo:{name, mobile, email, password}, functype: add/edit/remove
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/InstUserCrud',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchlogs_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/fetchlogs',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

//products

export const addproduct_api = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/addproduct',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchproductsAPI = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/fetchproducts',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const FetchAllProductOptions_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/FetchAllProductOptions',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const CRUDAllProductOptions_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/CRUDAllProductOptions',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const ProductOptionsCrudAPI = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/productoptionscrud',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const ProductOptionsValuesCrudAPI = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/productoptionsvaluescrud',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchproductinfoAPI = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/fetchproductinfo',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const updateproductinfo_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/updateproductinfo',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const OptionsToProductscrud_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/OptionsToProductscrud',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const ProductVariantsCrud_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/ProductVariantsCrud',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const ValueOfOptionCrud_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/ValueOfOptionCrud',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const SearchProduct_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/SearchProduct',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchProductsMinimumQuantity_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/fetchProductsMinimumQuantity',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const FetchProductsLogs_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/FetchProductsLogs',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const ProductsActionWithArray_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/ProductsActionWithArray',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const ProductsTagsCRUD_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/ProductsTagsCRUD',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchproducttags_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'get',
        url: serverbaselink + '/instowner/fetchproducttags',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetch_ProductInventorySettings_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/fetch_ProductInventorySettings',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const FetchProductInventoryQuant_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/FetchProductInventoryQuant',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

//countries-cities-states
export const fetch_countries = (axiosdata) => {
    // RESPONSE => countries [{}]
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/fetchcountries',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const fetch_states = (axiosdata) => {
    // Parameters => country_id. RESPONSE => countries [{}]
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/fetchstates',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetch_cities = (axiosdata) => {
    // Parameters => country_id. RESPONSE => countries [{}]
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/fetchcities',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

//orders

export const fetch_orders = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/fetchorders',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const add_order = (axiosdata) => {
    // customerid: Fixed Variables: "allorders","filter"
    // itemsarray: array of objects that has productid,isvariant,variantid
    // fullfilmstatus: ['Fulfilled','Unfulfilled','Partially Fulfilled'] send any of these
    // paidstatus: ['Paid','Unpaid'] send any of these
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/AddPOSorder',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const crud_order = (axiosdata) => {
    // functype	enum	Fixed Variables: "editstatus","additemtoorder","removeitemfromorder","deleteorder"
    // orderid	text	used in all functypes.
    // fullfilmstatus	enum	enum:['Fulfilled','Unfulfilled','Partially Fulfilled'] send any of these when functype is editstatus
    // paidstatus	enum	enum:['Paid','Unpaid'] send any of these when functype is editstatus
    // productid	text	when functype is additemtoorder
    // isvariant	text	when functype is additemtoorder
    // variantid	text	when functype is additemtoorder
    // orderitemid	text	when functype is removeitemfromorder and you can find it in get orders array.
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/ordercrud',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetch_order_information = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/fetchorderinformation',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const Apply_PromoCode_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/ApplyPromoCode',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const FetchOrderStatus_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/FetchOrderStatus',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const OrderSearch_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/OrderSearch',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const FetchOrdersRecentActivity_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/FetchOrdersRecentActivity',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const Fetch_Order_Items_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/Fetch_Order_Items',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const SyncMExcel_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/syncMExcel',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchsynchistory_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/fetchsynchistory',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const MoneyInout_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/instowner/MoneyInout',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const FetchReportSummary_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/FetchReportSummary',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const Fetchfinance_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/fetchfinance',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const FinanceMutaion_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/financecrud',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const DealsMutaion_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/dealscrud',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const FetchDeals_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/fetchdeals',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const EmployerpaymentsMutation_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/employerpaymentscrud',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const Fetchemployer_payments_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/fetchemployerpayments',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const Fetchtabexprices_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/fetchtabexprices',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const Fetchleadrequests_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/fetchleadrequests',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const Fetchtabexpositions_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/fetchtabexpositions',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const fetchTickets = (axiosdata) => {
    const axiosfetch = axios({
        method: 'get',
        url: serverbaselink + '/fetchTickets',
        headers: axiosheaders,
        params: axiosdata,
    });
    return axiosfetch;
};
export const fetchTicketInfo = (axiosdata) => {
    const axiosfetch = axios({
        method: 'get',
        url: serverbaselink + '/fetchTicketInfo',
        headers: axiosheaders,
        params: axiosdata,
    });
    return axiosfetch;
};
export const addTicketReply_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/addTicketReply',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const fetchDealchangereqeusts_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/fetchDealchangereqeusts',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const accept_dealchangerequest_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/accept_dealchangerequest',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const crattendance_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/crattendance',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const fetchattend_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/fetchattend',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const updateuser_hrinfo_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/updateuser_hrinfo',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const adduser_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/adduserinfo',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const fetchappjsons_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/fetchappjsons',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const crappjson_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/crappjson',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const cloneRepoAndProcessAppJson = (axiosdata) => {
    var tempaxiosdata = { ...axiosdata };
    const axiosfetch = axios({
        method: 'get',
        url: 'https://siblings.site' + '/cloneRepoAndProcessAppJson',
        headers: axiosheaders,
        params: tempaxiosdata,
    });
    return axiosfetch;
};
export const getAllSlots = (axiosdata) => {
    var tempaxiosdata = { ...axiosdata };
    const axiosfetch = axios({
        method: 'get',
        url: 'https://siblings.site' + '/getAllSlots',
        headers: axiosheaders,
        params: tempaxiosdata,
    });
    return axiosfetch;
};
export const fetchexacc_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/fetchexacc',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchInstitutes_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'get',
        url: serverbaselink + '/fetchinstitutes',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const getExpoAppProperties_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/getExpoAppProperties',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const dealInst_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/deal_c_inst',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const is_deal_has_appjson_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/is_deal_has_appjson',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchdealattach_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/fetchdealattach',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const fetchappjsonbuilds_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/fetchappjsonbuilds',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchmobiletemplatedonedeals_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/fetchmobiletemplatedonedeals',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchprogresswork_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'get',
        url: serverbaselink + '/fetchprogresswork',
        headers: axiosheaders,
        params: axiosdata,
    });
    return axiosfetch;
};

export const fetch_deployment_progresswork_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'get',
        url: serverbaselink + '/fetch_deployment_progresswork',
        headers: axiosheaders,
        params: axiosdata,
    });
    return axiosfetch;
};

export const add_acceptance_meeting_schedule_API = (axiosdata) => {
    axios.defaults.withCredentials = true;
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/add_acceptance_meeting_schedule',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetch_acceptance_meeting_schedule_API = (axiosdata) => {
    axios.defaults.withCredentials = true;
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/fetch_acceptance_meeting_schedule',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const fetchsurveys_API = (axiosdata) => {
    axios.defaults.withCredentials = true;
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/fetchsurveys',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const g_fetchdealinfo_API = (axiosdata) => {
    axios.defaults.withCredentials = true;
    const axiosfetch = axios({
        method: 'get',
        url: serverbaselink + '/g_fetchdealinfo',
        headers: axiosheaders,
        params: axiosdata,
    });
    return axiosfetch;
};
