import React, { Component, useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';

import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { fetchuseauthorization } from './API/API';

export const Loggedincontext = React.createContext();

export const Loggedincontext_provider = (props) => {
    const fetchuseauthorizationQueryContext = useQuery(['fetchuseauthorization'], () => fetchuseauthorization(), { keepPreviousData: true, staleTime: Infinity });
    const isuseradminContext = () => {
        var isuseradmin = false;
        if (fetchuseauthorizationQueryContext?.data?.data?.userinfo?.type == 'admin' || fetchuseauthorizationQueryContext?.data?.data?.userinfo?.type == 'superadmin') {
            isuseradmin = true;
        }

        return isuseradmin;
    };
    const [cookies, setCookie] = useCookies();
    axios.interceptors.request.use(function (config) {
        var defaultheaders = config.headers;
        var token = cookies['12312easdasdas32131asdsadsadsaqweasd123!@_#!@3123'];

        if (token != undefined) {
            // if (token.access != undefined) {
            defaultheaders.Authorization = 'Bearer ' + token;
            // }
        }
        config.headers = defaultheaders;
        return config;
    });

    const [isloggedincontext, setisloggedincontext] = useState(false);
    const [instbranchescontext, setinstbranchescontext] = useState([]);
    const [userloggedinfobjcontext, setuserloggedinfobjcontext] = useState({
        userinfo: {
            name: '',
            email: '',
            mobile: '',
            type: '',
            useractive: '',
            currentinstactive: '',
        },
        currentinstinfo: {
            instname: '',
            instlogo: '',
        },
    });

    return (
        <Loggedincontext.Provider
            value={{
                isloggedincontext,
                setisloggedincontext,
                userloggedinfobjcontext,
                setuserloggedinfobjcontext,
                instbranchescontext,
                setinstbranchescontext,
                fetchuseauthorizationQueryContext,
                isuseradminContext,
            }}
        >
            {props.children}
        </Loggedincontext.Provider>
    );
};

export const Loggedincontext_consumer = Loggedincontext.Consumer;
