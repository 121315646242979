import React, { Component } from 'react';
import Cookies from 'universal-cookie';
export const LanguageContext = React.createContext();
export class LanguageProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: {
                en: {
                    support_assistance_rating: 'How would you rate the support assistance you received?',
                    website_design_satisfaction: 'How satisfied are you with the design of your website?',
                    overall_website_satisfaction: 'Overall, how satisfied are you with the Tabex website?',
                    dashboard_usability: 'How user-friendly do you find the dashboard?',
                    mobile_application_selectbox: 'Do you have a mobile application?',
                    mobile_application_design_satisfaction: 'How satisfied are you with the design of your mobile application?',
                    overall_mobile_application_satisfaction: 'Overall, how satisfied are you with the Tabex mobile application?',
                    export: 'Export',
                    //reservation
                    addreservation: 'Add Reservation',
                    choosebranch: 'choose branch',
                    reservationdate: 'Reservtion Date',
                    reservationtime: 'Reservation Time',
                    numberofpeople: 'Number of People',
                    notes: 'Notes',
                    reservations: 'Reservations',
                    deletereservation: 'Delete Reservation',
                    editreservation: 'Edit Reservation',
                    reservationid: 'Reservation id',
                    diningarea: 'Dining Area',
                    choosediningarea: 'choose dining area',
                    outdoor: 'Outdoor',
                    indoor: 'Indoor',

                    reservationeditsuccess: 'Reservation is edited successfully',
                    reservationdeletesuccess: 'Reservation is deleted successfully',
                    reservationaddsuccess: 'Reservation is added successfully',
                    reservationadderror: 'Error In Adding Reservation. Please Contact Tabex Team',
                    reservationediterror: 'Error in Editing Reservation. Please Contact Tabex Team',
                    reservationadderror: 'Error in Updating Reservation Logo. Please Contact Tabex Team',
                    //home
                    totalsales: 'Total Sales',
                    totalcustomers: 'Total Customers',
                    totalorders: 'Total Orders',
                    websiteviews: 'Website Views',
                    mobileviews: 'Mobile Views',
                    update: 'Update',
                    //collections
                    allcollections: 'Collections',
                    collectionname: 'Collection Name',
                    productsquantity: 'Products Quantity',
                    addcollection: 'Add Collection',
                    filesuploaded: 'File Uploaded',
                    imageuploadedsuccess: 'Image Upload Success',
                    servererroruploadingimage: 'Server Error in Uploading Image',
                    description: 'Description',
                    productname: 'Product Name',
                    add: 'Add',
                    remove: 'Remove',
                    draganddropfiles: 'Drag & Drop Files or Click To Browse',
                    uploadlogo: 'Upload logo',
                    nocollections: 'No collections',
                    editcollection: 'Edit Collection',
                    collectionid: 'Collection id',
                    deletecollection: 'Delete collection',
                    chosenproducts: 'Chosen Products',
                    numberofproducts: 'Number of Products',
                    collectionindex: ' Collection Index',

                    collectionaddedsuccessfully: 'Collection is added successfully',
                    collectiondeletedsuccessfully: 'Collection is deleted successfully',
                    collectioneditedsuccessfully: 'Collection is edited successfully',
                    collectionlogoupdatesucess: 'Collection logo is updated successfully',
                    servererroraddingcollection: 'Error In Adding Collection. Please Contact Tabex Team',
                    servererroreditingcollection: 'Error in Editing Collection. Please Contact Tabex Team',
                    servererrorupdatecollectionlogo: 'Error in Updating Collection Logo. Please Contact Tabex Team',
                    itemisdeletedfromcollection: 'Product is removed from collection',
                    itemisaddedtocollection: 'Product is added to collection',
                    completecollectioninfo: 'Complete Collection info',

                    //customer
                    allcustomers: 'Customers',
                    addcustomer: 'Add Customer',
                    name: 'Name',
                    email: 'Email',
                    phonenumber: 'Phone Number',
                    orders: 'Orders',
                    state: 'State',
                    choosestate: 'choose state',
                    city: 'City',
                    choosecity: 'choose city',
                    country: 'Country',
                    choosecountry: 'choose country',
                    password: 'Password',
                    address: 'Address',
                    previousorders: 'Previous Orders',
                    orderid: 'Order ID',
                    items: 'Items',
                    total: 'Total',
                    status: 'Status',
                    nocustomers: 'No Customers',
                    editcustomer: 'Edit Customer',
                    deletecustomer: 'Delete Customer',
                    nocustomerfound: 'No customer found',

                    addcustomerservererror: 'Error in Adding Customer. Please Contact Tabex Team.',
                    editcustomerservererror: 'Error in Editing Customer. Please Contact Tabex Team.',
                    deletecustomerservererror: 'Error in Deleting Customer. Please Contact Tabex Team.',
                    customerhasbeensuccessfullyadded: 'Customer is added successfully ',
                    customerhasbeensuccessfullyedited: 'Customer information is edited Successfully ',
                    customerhasbeensuccessfullydeleted: 'Customer is deleted Successfully',
                    pleaseaddname: 'Please Add Name',

                    //header
                    logout: 'Logout',
                    english: 'English',
                    italian: 'Italian',
                    welcome: 'Welcome',

                    //home
                    engagement: 'Engagement',
                    thisday: 'This Day',
                    thisweek: 'This Week',
                    thismonth: 'This Month',
                    pending: 'Pending',

                    //orders
                    allorders: 'Orders',
                    orderstatus: 'Order Status',
                    fulfilment: 'Fulfilment',
                    paid: 'Paid',
                    delivered: 'Delivered',
                    fulfilled: 'Fulfilled',
                    unfulfilled: 'Unfulfilled',
                    unpaid: 'Unpaid',
                    shippingaddress: 'Shipping Address',
                    shippingnotes: 'Shipping Notes',
                    orderitems: 'Order Items',
                    quantity: 'Quantity',
                    delete: 'Delete',
                    ordersummary: 'Order Summary',
                    promocode: 'Promo Code',
                    remove: 'Remove',
                    paymentmethid: 'Payment Method',
                    cashondelivery: 'Cash on Delivery',
                    onlinepayment: 'Online Payment',
                    subtotal: 'Sub Total',
                    discount: 'Discount',
                    totalafterdiscount: 'Total after discount',
                    shipping: 'Shipping',
                    all: 'All',
                    addorder: 'Add Order',
                    noorders: 'No Orders',
                    pleasewriteyourpromocode: 'Please enter the promocode',
                    apply: 'Apply',
                    ordernumber: 'Order Number...',
                    preparing: 'Prepring',
                    outfordelivery: 'Out For Delivery',
                    settled: 'Settled',
                    deleteorder: 'Delete Order',

                    shippingzonefound: 'Shipping Zone Found',
                    promocoderemoved: 'Promo Code is Removed',
                    servererrorcheckingzones: 'Error In Checking Zones. Please Contact Tabex Team',
                    promocodeapplied: 'Promo Code Applied',
                    servererrorapplyingpromocode: 'Error In Applying Promo Codes. Please Contact Tabex Team',
                    pleasewriteyourpromocode: 'Please Write Your Promo Code',
                    orderisaddedsuccess: 'Order is added successfully',
                    servererroraddingorder: 'Error in Adding Order. Please Contact Tabex Team',
                    pleaseaddproducts: 'Please Add Products First',
                    pleasechoosefulfillmentstatus: 'Please Choose Fulfillment Status',
                    pleasechoosepaidstatus: 'Please Choose Paid Status',
                    pleasechooseorderstatus: 'Please Choose Order Status',
                    itemisaddedsuccess: 'Item is added successfully',
                    itemisdeletedsuccess: 'Item is deleted successfully',
                    orderisdeletedsuccess: 'Order is deleted successfully',
                    statusiseditedsuccess: 'Order Status is edited successfully',
                    servererrordeleteorder: 'Error in deleting order. Please Contact Tabex Team',
                    servererrorremoveitem: 'Error in removing item. Please Contact Tabex Team',
                    quantityisadded: 'Quantity is added',
                    completestepstoplaceorder: 'Complete steps to place the order',
                    servererroreditstatus: 'Error in editing order status. Please Contact Tabex Team',
                    pleaseaddquantity: 'Please add quantity',
                    servererrorsearchorders: 'Error In Searching For Order. Please Contact Tabex Team',
                    servererrorupdatingorders: 'Error In Updating Order. Please Contact Tabex Team',
                    orderupdatedsuccess: 'Order is Updated Successfully',
                    servererrorsearchvendors: 'Error In Searching For Vendors. Please Contact Tabex Team',
                    choosecountrystatecity: 'Please Choose Country, State & City',
                    doyouwanttodeleteorder: 'Do You Want To Delete This Order?',
                    servererroreditingorderstatus: 'Error In Editing Order Status. Please Contact Tabex Team',
                    orderstatusupdatedsuccess: 'Order Status is Updated Successfully',

                    //products
                    allproducts: 'Products',
                    addproduct: 'Add product',
                    collection: 'Collection',
                    salestatus: 'Sale Status',
                    hassale: 'Has Sale',
                    nosale: 'No Sale',
                    price: 'Price',
                    saleprice: 'Sale Price',
                    sku: 'SKU',
                    uploadproductimages: 'Upload Product Images',
                    uploadfiles: 'Upload Files',
                    choosenfiles: 'Choosen files',
                    pleaseremoveallunspportedfiles: 'Please remove all unsupported files.',
                    notavalidfiletype: 'Not Valied File Type',
                    limitedquantity: 'Limited Quantity',
                    alwaysavailable: 'Always Avaliable',
                    editproduct: 'Edit Product',
                    deleteproduct: 'Delete Product',
                    productavail: 'Product availability',
                    noproducts: 'No Products',
                    draft: 'Draft',
                    avaliability: 'Avaliability',
                    noimages: 'No images',
                    both: 'Both',
                    dine: 'Dine',
                    online: 'Online',
                    waytorecievethisproduct: 'Way to recieve this product',

                    optionsupdatessuccess: 'Options Updated Successfully',
                    servererrorupdateoptions: 'Error in Updating Options. Please contact Tabex Team',
                    valuealreadyexists: 'Value already exists',
                    pleasewritevaluename: 'Please Write Value Name',
                    pleasewritevalues: 'Please Write Values',
                    pleasewriteoptionname: 'Please Write Option Name',
                    productaddedsuccess: 'Product is added successfully',
                    servererroraddingproduct: 'Error in Adding Product. Please contact Tabex Team',
                    optionsupdatedsuccess: 'Product Options updated successfully',
                    servererroroptionsupdated: 'Error. Please Contact Tabex Team ',
                    deleteproductsuccess: 'Product is Deleted',
                    servererrordeleteproduct: 'Error in Deleting Product. Please contact Tabex Team',
                    errorupdateproduct: 'Error In Updating Product Information',
                    galleryimagedeletedsuccess: 'Image is deleted Successfully',
                    servererrordeletegalleryimage: 'Error in Deleting Image. Please Contact Tabex Team',
                    defaultimageupdatedsuccess: 'Default Image is Updated',
                    servererrorupdatedefaultimage: 'Error in Updating Default Image. Please Contact Tabex Team',
                    prodinfoupdatessuccess: 'Product Information is Updated Successfully',
                    servererrorupdateprodinfo: 'Error in Updating Product Information. Please Contact Tabex Team',

                    //promocode
                    allpromocodes: 'Promo Codes',
                    addpromocode: 'Add promocode',
                    editpromocode: 'Edit promocode',
                    deletepromocode: 'Delete promocode',
                    promocodename: 'Promo Code Name',
                    type: 'Type',
                    usages: 'Usages',
                    expirationdate: 'Expiration Date',
                    active: 'Active',
                    inactive: 'Inactive',
                    totaltobeused: 'Total To Be Used',
                    totaltobeusedbyeachcustomer: 'Total To Be Used By Each Customer',
                    usedby: 'Used By',
                    customersandguests: 'Customers and Guests',
                    customersonly: 'Customers Only',
                    percentage: 'Percentage',
                    value: 'Value',
                    nopromocodes: 'No Promocodes',

                    serveraddpromocodeerror: 'Error in Adding coupon, Please Contact Tabex Team.',
                    servereditpromocodeerror: 'Error in Editing coupon, Please Contact Tabex Team.',
                    serverdeletepromocodeerror: 'Error in Deleting coupon, Please Contact Tabex Team.',
                    couponaddedsuccess: 'Coupon is added successfully',
                    couponeditedsuccess: 'Coupon is edited successfully',
                    coupondeletedsucess: 'Coupon is deleted',
                    pleasecompletethemissingfields: 'Please Complete The Missing Fields',
                    thisproductalreadyexists: 'This Product Already Exists',

                    //users
                    allusers: 'Users',
                    adduser: 'Add User',
                    edituser: 'Edit User',
                    securitygroup: 'Security Group',
                    choosesecuritygroup: 'Choose Security Group',
                    nousersfound: 'No Users Found',
                    deleteuser: 'Delete User',
                    id: 'ID',
                    timestamp: 'Timestamp',
                    superadmin: 'Super Admin',
                    admin: 'Admin',
                    sales: 'Sales',
                    telesales: 'Telesales',
                    category: 'Category',
                    businessname: 'Business Name',
                    addclient: 'Add Client',
                    callid: 'Call ID',
                    clientname: 'Client Name',
                    followbackdate: 'FB Date',
                    followbacktime: 'FB Time',
                    addcall: 'Add Call',
                    callreport: 'Call Report',
                    callstatus: 'Call Status',
                    answered: 'Answered',
                    meeting: 'Meeting',
                    followup: 'Follow Up',
                    rejected: 'Rejected',
                    wrongnumber: 'Wrong Number',
                    didntanswer: "Didn't Answer",
                    callhistory: 'Call History',
                    todaycalls: 'Today Calls',
                    monthlycalls: 'Monthly Calls',
                    allcalls: 'All Calls',
                    todaymeetings: 'Today Meetings',
                    monthlymeetings: 'Monthly Meetings',
                    allmeetings: 'All Meetings',
                    filterstatus: 'Filter Status',
                    meetingdate: 'Meeting Date',
                    meetingtime: 'Meeting Time',
                    meetingid: 'Meeting ID',
                    addmeeting: 'Add Meeting',
                    clientid: 'Client ID',

                    servererroraddinguser: 'Error in Adding User. Please Contact Tabex Team',
                    servererroreditinguser: 'Error in Editing User. Please Contact Tabex Team',
                    servererrordeletinguser: 'Error in Deleting User. Please Contact Tabex Team',
                    usereditedsuccess: 'User is edited successfully',
                    useraddedsuccess: 'User is add successfully',
                    userdeletedsuccess: 'User is deleted successfully',

                    //securitylayers
                    securitylayers: 'Security Layers',
                    totalusers: 'Total Users',
                    addsecuritylayer: 'Add Security Layer',
                    securitygroupname: 'Security Group Name',
                    nosecuritylayers: 'No Security Layers',
                    pleaseaddsecuritygroupname: 'Please Add Security Group Name',
                    securitygpupdatedsuccess: 'Security Groups Updated Successfully',
                    servererrorupdatesecuritygp: 'Error In Updating Security Groups. Please Context Tabex Team',
                    edit: 'Edit',
                    nosecuritygroups: 'No Security Groups',
                    editsecuritylayer: 'Edit Security Layer',

                    //branches
                    branches: 'Branch',
                    addbranch: 'Add Branch',
                    editbranch: 'Edit Branch',
                    deletebranch: 'Delet Branch',
                    branchname: 'Branch Name',
                    createdon: 'Created On',
                    nobranches: 'No Branches',
                    numberoftables: 'Number of tables',

                    brancheditedsuccess: 'Branch is edited successfully',
                    branchaddessuccess: 'Branch is added successfully',
                    branchdeleted: 'Branch is deleted successfully',
                    errorinaddingbranchserver: 'Error in Adding Branch. Please Contact Tabex Team',
                    erroreditingbranchserver: 'Error in Editing Branch. Please Contact Tabex Team',
                    errordeletingbranchserver: 'Error in Deleting Branch. Please Contact Tabex Team',

                    clear: 'clear',

                    //parentcollections
                    parentcolections: 'Parent Collections',
                    addparentcollection: 'Add Parent Collection',
                    parentcollectionname: 'Parent Collection Name',
                    numberofcollections: 'Number of Collections',
                    chosencollections: 'Chosen Collections',
                    editparentcollection: 'Edit Parent Collection',
                    parentcollectionindex: 'Parent Collection Index',
                    editlogo: 'Update Logo',
                    noparentcollections: 'No Parent Collections',

                    thiscollectionalreadyexists: 'this collection already exists',
                    parentcollectionsupdatedsuccessfully: 'Parent Collection Updated Successfully',
                    parentcollectionsaddedsuccessfully: 'Parent Collection Added Successfully',
                    parentcollectionsdeletedsuccessfully: 'Parent Collection Deleted Successfully',
                    parentcollectionslogoupdatedsuccessfully: 'Parent Collection Logo Updated Successfully',
                    pleaseuploadimage: 'Please upload image',
                    errorinupdatingparentcollections: 'Error In Updating Parent Collections',

                    //shipping
                    updateshippingandaddressdetails: 'Update Adderss & Shipping Details',
                    shipping: 'Shipping',
                    chosenzones: 'Chosen Zones',
                    addzone: 'Add Zone',
                    zonename: 'Zone Name',
                    zonerateprice: 'Zone Rate Price',
                    zoneratesaleprice: 'Zone Rate Sale Price',
                    countries: 'Countries',
                    editzone: 'Edit Zone',
                    deletezone: 'Delete Zone',
                    youhavenotaddedanyzonesyet: 'No Zones have been added yet',
                    zonestates: 'Zone States',
                    shippingprice: 'Shipping Rate',
                    shippingsaleprice: 'Shipping Sale Price',
                    selectedzones: 'Selected Zones',
                    governorate: 'Governorate',
                    city: 'City',
                    cities: 'Cities',
                    shippingzones: 'Shipping Zones',
                    deliverytime: 'Delivery Time',
                    inmunites: 'in muniutes',

                    servererroraddingzone: 'Error in Adding Zone. Please Contact Tabex Team',
                    servererroreditingzone: 'Error in Editing Zone. Please Contact Tabex Team',
                    servererrordeletingzone: 'Error in Deleting Zone. Please Contact Tabex Team',
                    zoneeditedsuccess: 'Zone is edited successfully',
                    zoneaddedsuccess: 'Zone is add successfully',
                    zonedeletedsuccess: 'Zone is deleted successfully',
                    allstatesselected: 'All Stated are selected',
                    allstatesunselected: 'All Stated are removed',
                },
            },
            langdetect: '',
            setlang: this.setlang,
        };
    }

    setlang = (key) => {
        const cookies = new Cookies();
        cookies.set('sitelang', key, { path: '/' });
        this.setState({
            langdetect: key,
        });
        window.location.reload();
    };

    componentDidMount() {
        const cookies = new Cookies();
        var lastlangselected = cookies.get('sitelang');
        if (lastlangselected == 'en') {
            this.setState({
                langdetect: 'en',
            });
        } else if (lastlangselected == 'ar') {
            this.setState({
                langdetect: 'en',
            });
            document.body.setAttribute('dir', 'rtl');
        } else {
            this.setState({
                langdetect: 'en',
            });
            document.body.setAttribute('dir', 'rtl');
            this.setlang('en');
        }
    }

    render() {
        var setlang = this.state.setlang;
        var lang = '';

        if (this.state.langdetect == 'en') {
            lang = this.state.lang.en;
            document.body.setAttribute('dir', 'ltr');
        } else if (this.state.langdetect == 'ar') {
            lang = this.state.lang.ar;
            document.body.setAttribute('dir', 'rtl');
        } else {
            lang = this.state.lang.ar;
            document.body.setAttribute('dir', 'rtl');
        }

        var langdetect = this.state.langdetect;
        return <LanguageContext.Provider value={{ lang, setlang, langdetect }}>{this.props.children}</LanguageContext.Provider>;
    }
}

export const LanguageContextConsumer = LanguageContext.Consumer;
