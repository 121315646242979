import React, { useContext, useState, useEffect } from 'react';
import { HiHome, HiOutlineOfficeBuilding } from 'react-icons/hi';
import { AiOutlineTag } from 'react-icons/ai';
import { MdAddIcCall, MdAlternateEmail, MdPayments } from 'react-icons/md';
import { FaUsers, FaUser, FaRegHandshake, FaHandshake, FaChartArea } from 'react-icons/fa';
import { AiOutlineUserAdd, AiOutlineLock, AiOutlineUser } from 'react-icons/ai';
import { BiCollection, BiHide, BiShow, BiSupport } from 'react-icons/bi';
import { BsFiletypeDoc, BsFillCalendarWeekFill, BsStickies } from 'react-icons/bs';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { RiCoinsFill, RiCoinsLine } from 'react-icons/ri';
import { Loggedincontext } from './Loggedincontext';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, onValue } from 'firebase/database';
import audiof from './alarm.mp3';
import { TbBrandGoogleAnalytics } from 'react-icons/tb';
export const Contexthandlerscontext = React.createContext();
export const Contexthandlerscontext_provider = (props) => {
    const [audio] = useState(new Audio(audiof));
    const FireBaseapp = initializeApp({
        apiKey: 'AIzaSyDyhIWtpJ1XV6TA4Su8aTXO6F6jqrxxVSk',
        authDomain: 'tabex-series-staff.firebaseapp.com',
        databaseURL: 'https://tabex-series-staff-default-rtdb.firebaseio.com',
        projectId: 'tabex-series-staff',
        storageBucket: 'tabex-series-staff.appspot.com',
        messagingSenderId: '360358368078',
        appId: '1:360358368078:web:84e5b3769f42df6ce4041f',
        measurementId: 'G-L3R6Q3CMHK',
    });
    const Firebasedb = getDatabase(FireBaseapp);
    let history = useHistory();
    const { fetchuseauthorizationQueryContext } = useContext(Loggedincontext);
    const [hidesidenav_context, sethidesidenav_context] = useState(true);
    const [imagepreviewcontext, setimagepreviewcontext] = useState(null);
    const [isnotifhere, setisnotifhere] = useState({ is_notif_now: 0, message: '' });
    const isAuth = (roles) => {
        return roles?.some((e) => e == fetchuseauthorizationQueryContext?.data?.data?.userinfo?.type) || false;
    };

    const [pagesarray_context, setpagesarray_context] = useState([
        {
            name: 'Home',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <HiHome size={20} />
                </i>
            ),
            roles: 'all',
            path: '/home',
        },
        {
            name: 'Users',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <FaUsers size={20} />
                </i>
            ),
            roles: ['admin', 'hr'],

            path: '/users',
        },
        {
            name: 'Acceptance Meeting Schedule',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <BsFillCalendarWeekFill size={20} />
                </i>
            ),
            roles: ['admin', 'onboarding'],

            path: '/schedule',
        },

        {
            name: 'Operation Requests',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <BsStickies size={20} />
                </i>
            ),
            roles: ['admin', 'onboarding'],

            path: '/operationrequests',
        },

        {
            name: 'Operation Progress',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <BsStickies size={20} />
                </i>
            ),
            roles: ['admin', 'onboarding'],

            path: '/operationprogress',
        },
        {
            name: 'Support Requests',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <BiSupport size={20} />
                </i>
            ),
            roles: ['admin', 'onboarding'],

            path: '/support',
        },

        {
            name: 'Attendance',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <FaUsers size={20} />
                </i>
            ),
            roles: ['admin', 'hr'],

            path: '/attendance',
        },

        {
            name: 'Campaign Leads',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <FaUser size={18} />
                </i>
            ),
            roles: ['admin', 'salesmangement', 'salesrep'],
            path: '/campaignleads',
        },
        {
            name: 'Leads',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <FaUser size={18} />
                </i>
            ),
            roles: ['admin', 'salesmangement', 'salesrep'],

            path: '/leads',
        },
        {
            name: 'Hunting',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <FaUser size={18} />
                </i>
            ),
            roles: ['admin', 'salesmangement', 'salesrep'],

            path: '/haunting',
        },
        {
            name: 'Calls',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <MdAddIcCall size={19} />
                </i>
            ),
            roles: ['admin', 'salesmangement', 'salesrep'],

            path: '/calls',
        },
        {
            name: 'Meetings',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <BsFillCalendarWeekFill size={19} />
                </i>
            ),
            roles: ['admin', 'salesmangement', 'salesrep'],

            path: '/meetings',
        },
        {
            name: 'Follow Ups',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <BsFillCalendarWeekFill size={19} />
                </i>
            ),
            roles: ['admin', 'salesmangement', 'salesrep'],

            path: '/followups',
        },
        {
            name: 'Business Plan',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <FaChartArea size={19} />
                </i>
            ),
            roles: ['admin'],

            path: '/businessplan',
        },
        {
            name: 'Finance',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <RiCoinsFill size={19} />
                </i>
            ),
            roles: ['admin'],

            path: '/finance',
        },
        {
            name: 'Deals',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <FaHandshake size={19} />
                </i>
            ),
            roles: 'all',
            path: '/deals?&page=0',
        },
        {
            name: 'Employer Payments',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <MdPayments size={19} />
                </i>
            ),
            roles: ['admin', 'salesmangement', 'salesrep'],

            path: '/mypayments',
        },
        {
            name: 'Finance Formats',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <BsFiletypeDoc size={19} />
                </i>
            ),
            roles: ['admin'],

            path: '/formats',
        },
        {
            name: 'Pricing',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <MdPayments size={19} />
                </i>
            ),
            roles: 'all',

            path: '/pricings',
        },
        {
            name: 'Campaign Analytics',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <TbBrandGoogleAnalytics size={19} />
                </i>
            ),
            roles: ['admin'],

            path: '/campaignanalytics',
        },
        {
            name: 'Positions',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <FaUsers size={19} />
                </i>
            ),
            roles: ['admin'],

            path: '/positions',
        },
        {
            name: 'Deployments',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <FaUsers size={19} />
                </i>
            ),
            roles: ['admin', 'onboarding'],

            path: '/indvidualaccounts',
        },
        {
            name: 'Deployment Progress',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <FaUsers size={19} />
                </i>
            ),
            roles: ['admin', 'onboarding'],

            path: '/deploymentprogress',
        },

        {
            name: 'Domain',
            isselected: false,
            icon: (
                <i class={'allcentered'}>
                    <MdAlternateEmail size={19} />
                </i>
            ),
            roles: ['admin'],

            path: '/purchasedomain',
        },
    ]);

    const [pagetitle_context, setpagetitle_context] = useState('');
    const settlefirebase = (userid) => {
        set(ref(Firebasedb, 'users/' + userid), { is_notif_now: 0, message: '' });
    };
    const playAudio = () => {
        // audio.load();
        audio.play();
    };
    useEffect(() => {
        if (fetchuseauthorizationQueryContext.isSuccess) {
            if (fetchuseauthorizationQueryContext?.data?.data?.loggedin) {
                settlefirebase(fetchuseauthorizationQueryContext?.data?.data?.userinfo?.id);
                getNotifications(fetchuseauthorizationQueryContext?.data?.data?.userinfo?.id);
            }
        }
    }, [fetchuseauthorizationQueryContext.isSuccess]);
    useEffect(() => {
        if (fetchuseauthorizationQueryContext.isSuccess) {
            if (fetchuseauthorizationQueryContext?.data?.data?.loggedin) {
                if (isnotifhere.is_notif_now != 0) {
                    playAudio();

                    setTimeout(() => {
                        alert(isnotifhere.message);
                    }, 500);
                    setTimeout(() => {
                        set(ref(Firebasedb, 'users/' + fetchuseauthorizationQueryContext?.data?.data?.userinfo?.id), { is_notif_now: 0, message: '' });
                    }, 1000);
                }
            }
        }
    }, [isnotifhere]);
    useEffect(() => {
        function handleClickOutside(event) {
            audio.pause();
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []); // Empty dependency array ensures the effect runs only once

    const getNotifications = (userid) => {
        const data = ref(Firebasedb, 'users/' + userid); // CHANGE 'chars' TO YOUR DATABASE NAME
        onValue(data, (snapshot) => {
            var newvalue = snapshot.val();
            if (newvalue.is_notif_now == 1) {
                setisnotifhere(newvalue);
            }
        });
    };
    const setpageactive_context = (route) => {
        var temparr = [...pagesarray_context];

        temparr.forEach((subsideelement, index) => {
            if (subsideelement.path == route) {
                subsideelement['isselected'] = true;
            } else {
                subsideelement['isselected'] = false;
            }
        });

        setpagesarray_context([...temparr]);
    };
    const dateformatter = (date) => {
        // var string = date;

        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', hour12: true };
        return new Date(date).toLocaleDateString(undefined, options);
    };
    return (
        <Contexthandlerscontext.Provider
            value={{
                playAudio,
                pagesarray_context,
                setpagesarray_context,
                pagetitle_context,
                setpagetitle_context,
                setpageactive_context,
                hidesidenav_context,
                sethidesidenav_context,
                dateformatter,
                imagepreviewcontext,
                setimagepreviewcontext,
                isAuth,
            }}
        >
            {props.children}
        </Contexthandlerscontext.Provider>
    );
};
